/* Landing.css */

/* Landing Container */
.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
  padding-top: 60px; /* Match the header height */
  transition: transform 0.3s ease-in-out;
}

/* Header for Landing Page */
.landing-header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  z-index: 1000;
}

.landing-header h1 {
  font-size: 2rem;
  margin-left: 20px;
}

/* Content for Landing Page */
.landing-content {
  margin-top: 100px;
  text-align: center;
}

.landing-content p {
  font-size: 1.5rem;
  color: #333;
}

/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #333;
  color: white;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  padding: 30px;
  z-index: 1000;
}

.sidebar.visible {
  transform: translateX(0);
}

.sidebar .button-container {
  margin-top: 100px;
}

/* Background Overlay */
.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
  pointer-events: none;
  backdrop-filter: blur(0px);
  opacity: 0;
  transition: backdrop-filter 0.3s ease, opacity 0.3s ease;
}

.background-overlay.visible {
  pointer-events: all;
  backdrop-filter: blur(3px);
  opacity: 1; 
}

.video-background {
  position: absolute;
  z-index: 1;
  top: 0; /* Start from the top of the container */
  left: 50%;
  width: 100vw;
  height: auto;
  transform: translate(-50%, -32%);
  object-fit: cover;
  clip-path: inset(33.33% 0 15% 0);
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: white;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.bottom-blur {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  z-index: 1;
}