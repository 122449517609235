/* Sidebar.css */

/* Sidebar Container */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: #333;
    color: white;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    padding: 30px;
    z-index: 1099;
  }
  
  .sidebar.visible {
    transform: translateX(0);
  }
  
  .sidebar .button-container {
    margin-top: 100px;
  }
  
  /* Sidebar Button Styles */
  .sidebar-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    background-color: #444;
    color: white;
    text-align: left;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sidebar-button:hover {
    background-color: #555;
  }
  
  /* Background Overlay */
  .background-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1099;
    background: rgba(0, 0, 0, 0.3);
    pointer-events: none;
    backdrop-filter: blur(0px);
    opacity: 0;
    transition: backdrop-filter 0.3s ease, opacity 0.3s ease;
  }
  
  .background-overlay.visible {
    pointer-events: all;
    backdrop-filter: blur(3px);
    opacity: 1;
  }
  
  /* Sidebar Link Styles */
.sidebar-link {
  position: relative;
  display: block;
  padding: 10px 0;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

/* Hover Animation for Sidebar Links */
.sidebar-link:hover {
  color: #ddd; /* Change color on hover if needed */
}

.sidebar-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #fff; /* Line color */
  transition: width 0.3s ease;
}

.sidebar-link:hover::after {
  width: 100%; /* Full width on hover */
}
