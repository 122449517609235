.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000; /* Higher than the sidebar overlay */
  }
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
    z-index: 2001; /* Ensure it's above the overlay */
  }
  
  .popup-content h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #ff4500;
  }
  
  .popup-content p {
    font-size: 1em;
    color: #333;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  