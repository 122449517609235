/* Universal App Styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
  font-family: 'Lato', Lato;
}

.App {
  text-align: center;
}

.header-container {
  position: fixed; /* Fixes the header to the top */
  top: 0;
  left: 0;
  width: 100%;
  height: 60px; /* Adjust this to match the actual height of your header */
  background-color: #333; /* Header background color */
  z-index: 1100; /* Ensure it's above other content */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for the header */
}


.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.logo:hover {
  color: #ccc;
}

.login-section {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.login-button, .logout-button {
  background-color: #500000;
  color: white;
  padding: 10px 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.2s;
}

.login-button:hover, .logout-button:hover {
  background-color: #750000;
}

.profile-pic {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Hamburger Icon Styles */
.hamburger-container {
  cursor: pointer;
  display: inline-block;
  position: relative;
  z-index: 1300; /* Ensure it's above sidebar */
  width: 35px; 
  height: 30px; 
}

.hamburger-button{
  position: relative;
  z-index: 1100;
}

.hamburger {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out;
}

.line {
  width: 100%;
  height: 4px;
  background-color: white;
  transition: all 0.3s ease-in-out;
  border-radius: 2px;
}

.hamburger.open .line1 {
  transform: rotate(45deg) translate(10px, 10px);
}

.hamburger.open .line2 {
  opacity: 0; /* Hide the middle line */
}

.hamburger.open .line3 {
  transform: rotate(-45deg) translate(8px, -8px);
}

/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #333;
  color: white;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  padding: 30px;
  z-index: 1099; /* Below hamburger, but above content */
}

.sidebar.visible {
  transform: translateX(0);
}

.sidebar .button-container {
  margin-top: 100px;
}

/* Sidebar Button Styles */
.sidebar-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  background-color: #444;
  color: white;
  text-align: left;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar-button:hover {
  background-color: #555;
}

/* Background Overlay Styles */
.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1099; /* Below sidebar */
  background: rgba(0, 0, 0, 0.3);
  pointer-events: none;
  backdrop-filter: blur(0px);
  opacity: 0;
  transition: backdrop-filter 0.3s ease, opacity 0.3s ease;
}

.background-overlay.visible {
  pointer-events: all;
  backdrop-filter: blur(3px);
  opacity: 1; 
}

/* Modals */
.fixed-modal, .message-modal {
  position: fixed;
  inset: 0; /* Fullscreen overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1200; /* Above sidebar */
}

.fixed-modal .bg-white,
.message-modal .bg-white {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Adjust main content to account for the fixed header */
.content-container {
  margin-top: 60px; /* Pushes content below the header (adjust this if your header height changes) */
  padding: 20px; /* Adds some spacing for the content */
  height: calc(100vh - 60px); /* Ensures the content fills the rest of the viewport */
  overflow-y: auto; /* Enables scrolling if content overflows */
  background-color: #f9f9f9; /* Optional background color for the main area */
  box-sizing: border-box; /* Ensures padding is included in height calculations */
}


/* Dashboard-specific styling */
.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dashboard-main {
  display: flex;
  flex: 1;
  overflow: hidden; /* Prevents overflow scrollbars */
}

.dashboard-calendar {
  flex: 2; /* 2/3 of the space */
  padding: 20px;
  box-sizing: border-box;
  border-right: 1px solid #ddd; /* Optional divider */
}

.dashboard-sidebar {
  flex: 1; /* 1/3 of the space */
  padding: 20px;
  box-sizing: border-box;
  background-color: #edf6ff; /* Optional: Sidebar background */
  overflow-y: auto; /* Ensure sidebar is scrollable */
}


/* Animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
