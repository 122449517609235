/* Ensure the calendar stays below the header */
.dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  /* Header styling (adjust this to match your existing header styles) */
  .dashboard-header {
    height: 60px; /* Adjust to match the height of your header */
    background-color: #3666b3; /* Example color */
    color: white;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 1.5rem;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Main content area below the header */
  .dashboard-main {
    display: flex;
    flex: 1; /* Take the remaining vertical space */
    overflow: hidden; /* Prevents scrollbars if content overflows */
  }
  
  /* Calendar styling */
.dashboard-calendar {
  width: 66.66%; /* Take 2/3 of the screen width */
  padding: 20px;
  box-sizing: border-box;
  border-right: 1px solid #ddd; /* Optional: divider between sections */
  background-color: #ffffff; /* White background for the calendar */
}
  
  /* Optional right-side content styling (if needed) */
.dashboard-sidebar {
  width: 33.33%; /* Remaining 1/3 of the screen */
  padding: 20px;
  box-sizing: border-box;
  background-color: #500000 !important; /* Maroon background */
  color: #ffffff; /* White text for better contrast */
  overflow-y: auto; /* Scrollable if content overflows */
}

.dashboard-sidebar p {
  color: #ffffff; /* White text */
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1.5;
}
  
  /* Calendar container for proper alignment */
  .calendar-container {
    height: calc(100% - 40px); /* Adjust for padding or margins */
    background-color: white; /* Background for the calendar area */
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  /* Sidebar Buttons */
  .sidebar-button {
    display: block;
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    background-color: #ffffff !important; /* White */
    color: #000000 !important; /* Black text */
    text-align: center;
    /*border: 2px solid #000000 !important; /* Black border */
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .sidebar-button:hover {
    background-color: #a52a2a; /* Lighter maroon on hover */
    transform: scale(1.02); /* Slight zoom effect on hover */
  }
  
  /* Modal Overlay */
  .modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1200;
  }
  
  /* Modal Content */
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .modal-content h3 {
    margin-bottom: 20px;
  }
  
  .modal-content form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .modal-content form input,
  .modal-content form select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .save-button {
    background-color: #28a745;
    color: white;
    padding: 8px 16px;
    border: 2px solid #ffffff; /* White border */
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .cancel-button {
    background-color: #dc3545;
    color: white;
    padding: 8px 16px;
    border: 2px solid #ffffff; /* White border */
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .save-button:hover {
    background-color: #218838;
    transform: scale(1.05);
  }
  
  .cancel-button:hover {
    background-color: #c82333;
    transform: scale(1.05);
  }

  .notification-popup {
    position: fixed;
    bottom: 40px;
    right: 40px;
    background-color: #500000;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 2000;
    animation: fadeIn 0.5s ease, fadeOut 0.5s ease 2.5s;
    border: 2px solid #ffffff; /* White border for better contrast */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(20px);
    }
  }
  
  .dashboard-sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
    font-size: 24px;
    font-weight: bold;
  }
  
  .dashboard-profile-pic {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border: 2px solid #ffffff; /* White border for better contrast */
  }
  
  
  